function initSubMenu(subMenuTogglers) {
    const clearSubMenus = () => {
        let cleared = false;
        for (let i = 0; i < subMenuTogglers.length; i++) {
            if (subMenuTogglers[i].classList.contains('submenu-open')) {
                subMenuTogglers[i].classList.remove('submenu-open');
                cleared = true;
            }
        }
        return cleared;
    };
    const subMenuHandler = (el) => {
        if (el.classList.contains('submenu-open')) {
            el.classList.remove('submenu-open');
        } else if (clearSubMenus()) {
            setTimeout(() => {
                el.classList.add('submenu-open');
            }, 0);
        } else {
            el.classList.add('submenu-open');
        }
    };
    for (let i = 0; i < subMenuTogglers.length; i++) {
        subMenuTogglers[i].querySelector('a').addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            subMenuHandler(subMenuTogglers[i]);
        });
        // Prevent Submenu from closing when someone clicks on it
        subMenuTogglers[i].querySelector('.children').addEventListener('click', (e) => {
            e.stopPropagation();
        });
    }
}

const productMenu = document.querySelector('.product-category-menu');
if (productMenu) {
    const secondLevelSubMenuTogglers = productMenu.querySelectorAll('.has-children');

    if (secondLevelSubMenuTogglers.length) {
        initSubMenu(secondLevelSubMenuTogglers);
    }
}
