import Swiper from 'swiper';
import SimpleFadeSlider from './simpleFadeSlider';

function extend(obj, src) {
    /* eslint no-param-reassign:off */
    Object.keys(src).forEach((key) => { obj[key] = src[key]; });
    return obj;
}

const initSlider = (slider) => {
    const defaultSliderOptions = {
        loop: true,
        effect: 'fade',
        speed: 800,
        simulateTouch: false,
        autoplay: 4000,
        nextButton: slider.querySelector('.js-slider-button-next'),
        prevButton: slider.querySelector('.js-slider-button-prev'),
    };
    const heroSliderOptions = {
        speed: 1000,
        autoplay: 4000,
    };
    const newsSliderOptions = {
        speed: 600,
    };

    let sliderOptions = defaultSliderOptions;

    if (slider.className.includes('hero-slider')) {
        sliderOptions = extend(sliderOptions, heroSliderOptions);
    } else if (slider.className.includes('news-slider')) {
        sliderOptions = extend(sliderOptions, newsSliderOptions);
    }
    const slides = slider.querySelectorAll('.swiper-slide');

    // disable controls, loop, autoplay when just one slide
    if (slides.length < 2) {
        const sliderControls = slider.querySelector('.slider-controls');
        sliderOptions.loop = false;
        sliderOptions.autoplay = false;
        sliderControls.classList.add('disabled');
    }
    /* eslint no-new:off */
    new Swiper(slider, sliderOptions);
};

export default function initSliders() {
    // Swiper Sliders
    const swiperSliders = document.querySelectorAll('.js-slider');
    if (swiperSliders) {
        for (let i = 0; i < swiperSliders.length; i++) {
            initSlider(swiperSliders[i]);
        }
    }
    // Fade Sliders
    const fadeSliders = document.querySelectorAll('.js-fade-slider');
    if (fadeSliders.length) {
        for (let i = 0; i < fadeSliders.length; i++) {
            new SimpleFadeSlider(fadeSliders[i]);
        }
    }
}
