import initLightbox from './lightbox';
import initSliders from './sliders';
import initScrollTop from './scrollTop';
import { isTouchDevice } from './helpers';


$(".site-header__outter-menu span").on('click', function() {
    if($('.search_form').hasClass('active')){
        $('.search_form').removeClass('active');
    }else{
      $('.search_form').addClass('active');
    }
    return false;
});

$(".site-header__sticky-menu-wrapper span").on('click', function() {
    if($('.search_form').hasClass('active')){
        $('.search_form').removeClass('active');
    }else{
      $('.search_form').addClass('active');
    }
    return false;
});

$(".chevron").on('click', function() {
    if($('.language-switcher').hasClass('active')){
         $('.language-switcher').removeClass('active');
    }else{
      $('.language-switcher').addClass('active');
    }
    return false;
});

$(".search_form.active span").on('click', function() {
    if($('.search_form').hasClass('active')){
        $('.search_form').removeClass('active');
    }
    return false;
});


function menuDoubleTapToGo() {
    const parentMenuItems = document.querySelectorAll('.menu-item-has-children > a');
    let currentActiveMenuItem = '';
    if (!parentMenuItems || !isTouchDevice()) {
        return;
    }
    const clickHandler = (e) => {
        if (e.target !== currentActiveMenuItem) {
            e.preventDefault();
            currentActiveMenuItem = e.target;
        }
    };
    for (let i = 0; i < parentMenuItems.length; i++) {
        parentMenuItems[i].addEventListener('click', clickHandler);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    // Menu toggle
    const toggleMenuButton = document.querySelector('.js-toggle-menu-button');
    if (toggleMenuButton) {
        const shadower = document.querySelector('.js-shadower');
        shadower.addEventListener('click', (e) => {
            e.stopPropagation();
            document.body.classList.remove('menu-open');
            document.body.classList.remove('noscroll');
        }, false);
        document.addEventListener('click', (e) => {
            if (e.target.classList.contains('js-toggle-menu-button')) {
                e.stopPropagation();
                document.body.classList.toggle('menu-open');
                document.body.classList.toggle('noscroll');
            }
        }, false);
    }
    // Language Switcher Toggle
    const toggleLanguageSwitcherButton = document.getElementById('js-ls-toggle-button');
    if (toggleLanguageSwitcherButton) {
        const languageSwitcher = document.querySelector('.language-switcher');
        toggleLanguageSwitcherButton.addEventListener('click', (e) => {
            e.stopPropagation();
            languageSwitcher.classList.toggle('active');
        }, false);
    }
    // Scroll to top button
    const scrollTopButton = document.getElementById('js-scroll-top');
    if (scrollTopButton) {
        initScrollTop(scrollTopButton);
    }
    // Lightbox
    initLightbox();
    // Sliders
    initSliders();

    // Auto Play Videos
    const autoPlayVideos = document.querySelectorAll('.js-vd-autoplay');
    if (autoPlayVideos && window.innerWidth >= 768) {
        for (let i = 0; i < autoPlayVideos.length; i++) {
            autoPlayVideos[i].play();
            // TODO: ADD PLAY VIDEO BUTTON FOR MOBILE
        }
    }
    // Header Sticky Elements
    const headerScrollHandler = () => {
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        const offset = windowHeight * 0.3;

        let ticking = false;
        if (!ticking) {
            window.requestAnimationFrame(() => {
                if (window.scrollY > offset) {
                    document.body.classList.add('sticky-visible');
                } else {
                    document.body.classList.remove('sticky-visible');
                }
                ticking = false;
            });
        }
        ticking = true;
    };
    window.addEventListener('scroll', headerScrollHandler, { passive: true });
    // Init menu double tap to activate link
    menuDoubleTapToGo();
});
