/* eslint-disable */

export function getAjax(url, success) {
    const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
    xhr.open('GET', url);
    xhr.onreadystatechange = () => {
        if (xhr.readyState > 3 && xhr.status === 200) {
            success(xhr.responseText);
        }
    };
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send();
    return xhr;
}

export function postAjax(url, data, success) {

    var params = typeof data === 'string' ? data : Object.keys(data).map(
        function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
        }
    ).join('&');

    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject("Microsoft.XMLHTTP");
    xhr.open('POST', url);
    xhr.onreadystatechange = function () {
        if (xhr.readyState > 3 && xhr.status === 200) {
            success(xhr.responseText);
        }
    };
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.send(params);
    return xhr;
}

export function loadGoogleMapsAPI(options) {
    const CALLBACK_NAME = '__googleMapsApiOnLoadCallback';

    const OPTIONS_KEYS = ['client', 'key', 'language', 'region', 'v'];

    options = options || {};

    return new Promise(function (resolve, reject) {
        // Exit if not running inside a browser.
        if (typeof window === 'undefined') {
            return reject(
                new Error('Can only load the Google Maps API in the browser')
                );
        }

        // Reject the promise after a timeout.
        var timeoutId = setTimeout(function () {
            window[CALLBACK_NAME] = function () {}; // Set the on load callback to a no-op.
            reject(new Error('Could not load the Google Maps API'));
        }, options.timeout || 10000);

        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            return resolve(console.log('Google Maps API already loaded'));
        }

        // Hook up the on load callback.
        window[CALLBACK_NAME] = function () {
            if (timeoutId !== null) {
                clearTimeout(timeoutId);
            }
            resolve(window.google.maps);
            delete window[CALLBACK_NAME];
        };

        // Prepare the `script` tag to be inserted into the page.
        const scriptElement = document.createElement('script');

        const params = ['callback=' + CALLBACK_NAME];
        OPTIONS_KEYS.forEach(function (key) {
            if (options[key]) {
                params.push(key + '=' + options[key]);
            }
        });
        if (options.libraries && options.libraries.length) {
            params.push('libraries=' + options.libraries.join(','));
        }
        scriptElement.src = 'https://maps.googleapis.com/maps/api/js?' + params.join('&');
        scriptElement.defer = 'defer';
        scriptElement.async = 'async';

        // Insert the `script` tag.
        document.body.appendChild(scriptElement);
    });
}

export function decodeHtml(html) {
    let txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds.
export function debounce(fn, delay) {
    var timer = null;
    return function () {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
}

export function isTouchDevice() {
    const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    const mq = function (query) {
        return window.matchMedia(query).matches;
    };

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}
export default {getAjax, postAjax, loadGoogleMapsAPI, decodeHtml, debounce, isTouchDevice};
