/* eslint-disable */
/* global themeOpts, google */

let mapWrappers = document.querySelectorAll('.js-contact-map');
if (mapWrappers.length) {
    function isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    let initMapControl = function (el) {
        let coords = themeOpts.mapCoords.split(',');
        let centerPos = new google.maps.LatLng({ lat: parseFloat(coords[0]), lng: parseFloat(coords[1]) });
        let mapOptions = {
            center: centerPos,
            zoom: 14,
            scrollwheel: false,
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER,
            },
        };
        if (isJson(themeOpts.mapStyles)) {
            mapOptions.styles = JSON.parse(themeOpts.mapStyles);
        }
        let map = new google.maps.Map(el, mapOptions);
        let marker = new google.maps.Marker({
            position: centerPos,
            map: map,
            icon: themeOpts.mapMarker

        });
    };
    let addGoogleMapsApi = () => {
        window.initThemeMaps = function () {
            for (let i = 0; i < mapWrappers.length; i++) {
                initMapControl(mapWrappers[i]);
            }
        };
        document.addEventListener('DOMContentLoaded', () => {
            if (typeof google === 'object' && typeof google.maps === 'object') {
                initThemeMaps();
            } else {
                let s = document.createElement('script');
                s.src = 'https://maps.googleapis.com/maps/api/js?key=' + themeOpts.mapApiKey + '&callback=initThemeMaps';
                s.defer = 'defer';
                s.async = 'async';
                document.body.appendChild(s);
            }
        });
    };

    addGoogleMapsApi();
}