import { debounce } from '../helpers';

const isInViewport = (element) => {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const offset = windowHeight * 0.7;

    // Properties other than width and height are relative to the top-left of the viewport.
    // top/bottom are 0 when they are on window top,
    // minus above top, positive below top

    const isTopInView = rect.top >= 0 && rect.top <= offset;
    // const isBottomInView = rect.bottom >= 0 && rect.bottom <= offset;
    // const isCenterInView = rect.top < 0 && rect.bottom >= windowHeight;
    /*
    if (isTopInView || isBottomInView || isCenterInView) {
        console.log('Element: ', element);
        console.log('isTopInView: ', isTopInView);
        console.log('isBottomInView: ', isBottomInView);
        console.log('isCenterInView: ', isCenterInView);
    }
    */
    // return isTopInView || isBottomInView || isCenterInView;
    return isTopInView; // we only care about topInView
};

document.addEventListener('DOMContentLoaded', () => {
    // check if inview animations are on
    if (document.querySelector('.js-vis')) {
        const watchedElements = Array.prototype.slice.call(document.querySelectorAll('.js-check-vis'));
        if (watchedElements.length) {
            const scrollHandler = debounce(() => {
                for (let i = 0; i < watchedElements.length; i++) {
                    if (isInViewport(watchedElements[i])) {
                        watchedElements[i].classList.add('animate', 'show');
                        watchedElements.splice(i, 1);
                        i -= 1;
                    }
                }
                if (!watchedElements.length) {
                    window.removeEventListener('scroll', scrollHandler);
                    window.removeEventListener('resize', scrollHandler);
                }
            }, 80);
            window.addEventListener('scroll', scrollHandler, { passive: true });
            window.addEventListener('load', scrollHandler);
            window.addEventListener('resize', scrollHandler, { passive: true });
        }
    }
});
