export default class SimpleFadeSlider {
    constructor(slider) {
        this.slider = slider;
        this.slides = this.slider.querySelectorAll('.slide');
        this.numberOfSlides = this.slides.length;
        this.init();
    }
    init() {
        if (this.numberOfSlides > 1) {
            this.currentIndex = 0;
            this.autoplay();
        }
    }
    autoplay() {
        setInterval(this.switchSlide.bind(this), 4000);
    }
    activateSlide(index) {
        this.slides[index].classList.add('active');
    }
    deactivateSlide(index) {
        this.slides[index].classList.remove('active');
    }
    switchSlide() {
        this.deactivateSlide(this.currentIndex);
        this.currentIndex += 1;
        if (this.currentIndex === this.numberOfSlides) {
            this.currentIndex = 0;
        }
        this.activateSlide(this.currentIndex);
    }
}
