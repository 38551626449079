import SimpleLightbox from 'simple-lightbox';

function parseHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html.trim();
    return div.childNodes[0];
}
function getWindowHeight() {
    return 'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
}
class FinoLightbox extends SimpleLightbox {
    init(options) {
        super.init(options);
    }
    setupLightboxHtml() {
        const o = this.options;
        if (!this.$el) {
            this.$el = parseHtml(
                `<div class="slbElement ${o.elementClass}">
                    <div class="slbOverlay"></div>
                    <div class="slbWrapOuter">
                        <div class="slbWrap">
                            <div class="slbContentOuter">
                                <div class="slbContent"></div>
                                <button type="button" title="${o.closeBtnCaption}" class="slbCloseBtn ${o.closeBtnClass}">
                                    <span class="button__icon"><svg class="icon-close"><use xlink:href="#close"></use></svg></span>
                                </button>
                                ${this.items.length > 1 ? `
                                <div class="slbArrows">
                                    <button type="button" title="${o.prevBtnCaption}" class="prev slbArrow ${o.prevBtnClass}">
                                        <span class="button__caption">${o.prevBtnCaption}</span>
                                        <span class="button__icon"><svg class="icon-angle-left"><use xlink:href="#angle-left"></use></svg></span>
                                    </button>
                                    <button type="button" title="${o.nextBtnCaption}" class="next slbArrow ${o.nextBtnClass}">
                                        <span class="button__caption">${o.nextBtnCaption}</span>
                                        <span class="button__icon"><svg class="icon-angle-right"><use xlink:href="#angle-right"></use></svg></span>
                                    </button>
                                </div>` : ''}
                            </div>
                        </div>
                    </div>
                </div>`);
            this.$content = this.$el.querySelector('.slbContent');
        }
        this.$content.innerHTML = '';
        return this;
    }
    setImageDimensions() {
        if (this.$currentImage) {
            const windowHeight = getWindowHeight();
            const maxImageHeight = (windowHeight > 800) ? windowHeight - 200 : windowHeight - 100;
            this.$currentImage.style.maxHeight = `${maxImageHeight}px`;
        }
    }
}

export default function initLightbox() {
    const lightboxElements = document.querySelectorAll('.js-lightbox');
    if (lightboxElements.length) {
        for (let i = 0; i < lightboxElements.length; i++) {
            /* eslint no-new:off */
            new FinoLightbox({
                elements: lightboxElements[i].querySelectorAll('.js-lightbox-link'),
                captionAttribute: 'data-caption',
            });
        }
    }
}
