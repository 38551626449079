export default function initScrollTop(btn) {
    function scrollToTop(step) {
        window.scrollTo(0, window.scrollY - step);
        if (window.scrollY > 0) {
            window.requestAnimationFrame(() => {
                scrollToTop(step);
            });
        }
    }
    function calculateScrollStep(seconds) {
        const step = window.scrollY / (seconds * 60);
        return step;
    }
    btn.addEventListener('click', (e) => {
        e.preventDefault();
        scrollToTop(calculateScrollStep(1));
    });
}
