/* eslint no-param-reassign: off */
const projectsContainer = document.querySelector('.js-project-items-container');
if (projectsContainer) {
    // Helper Functions
    const getInitialOffsetTop = (object, offset) => {
        offset = offset || 0;
        offset += object.offsetTop;
        if (object.offsetParent) {
            offset = getInitialOffsetTop(object.offsetParent, offset);
        }
        return offset;
    };
    const elementSetTransform = (el, translateY) => {
        el.style.transform = `translate3d(0, ${translateY}px, 0)`;
    };

    // Declaration of variables needed later
    let projects = false;
    let middleColumnProjects = [];
    let windowWidth = window.innerWidth || document.documentElement.clientWidth;
    let containerOffsetTop = getInitialOffsetTop(projectsContainer);

    // Event Handlers
    const projectsScrollHandler = () => {
        const rect = projectsContainer.getBoundingClientRect();
        const projectsContainerTop = rect.top;
        const projectsContainerBottom = rect.bottom;
        if (projectsContainerBottom < 0 || windowWidth < 1200) {
            // Container is completely out of view and we are below it
            // Or window is smaller than 1200px and we have 2 columns
            return;
        }
        const translateY = (projectsContainerTop - containerOffsetTop) / 20;
        middleColumnProjects.forEach((el) => {
            elementSetTransform(el, translateY);
        });
    };
    const projectsResizeHandler = () => {
        containerOffsetTop = getInitialOffsetTop(projectsContainer);
        windowWidth = window.innerWidth || document.documentElement.clientWidth;
        if (windowWidth < 1200) {
            middleColumnProjects.forEach((el) => {
                elementSetTransform(el, 0);
            });
        } else {
            projectsScrollHandler();
        }
    };
    // Init Functions
    const initParallaxScroll = () => {
        const tmplc = document.querySelector('.term-idiotika-erga');
        if(tmplc){
            projects = projectsContainer.querySelectorAll('.js-project-item');
            for (let i = 1; i < projects.length; i += 3) {
                middleColumnProjects.push(projects[i]);
            }
        }   
    };
    const reInitParallaxScroll = () => {
        window.removeEventListener('scroll', projectsScrollHandler);
        projects = projectsContainer.querySelectorAll('.js-project-item');
        middleColumnProjects = [];
        for (let i = 1; i < projects.length; i += 3) {
            middleColumnProjects.push(projects[i]);
        }
        projectsScrollHandler();
        projectsResizeHandler();
        window.addEventListener('scroll', projectsScrollHandler, { passive: true });
    };

    initParallaxScroll();
    if (middleColumnProjects.length > 1) {
        projectsScrollHandler();
        window.addEventListener('scroll', projectsScrollHandler, { passive: true });
        window.addEventListener('resize', projectsResizeHandler, { passive: true });
        document.addEventListener('loadMoreProjects', reInitParallaxScroll);
    }
}
