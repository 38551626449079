import imagesLoaded from 'imagesloaded';
import { getAjax } from './helpers';

document.addEventListener('DOMContentLoaded', () => {
    const loadMoreButton = document.querySelector('.js-load-more-button');
    const nextPageAnchor = document.querySelector('.next.page-numbers');
    if (loadMoreButton && nextPageAnchor) {
        const gridContainer = document.querySelector('.js-project-items-container');
        let url = nextPageAnchor.getAttribute('href');
        const loadMoreButtonHandler = () => {
            const projectsAddedEvent = new Event('loadMoreProjects');
            document.body.classList.add('loading-items');
            loadMoreButton.classList.add('loading');
            loadMoreButton.disabled = true;
            getAjax(
                url,
                (response) => {
                    let responseHTML = document.createElement('div');
                    responseHTML.innerHTML = response;
                    const gridItems = responseHTML.querySelectorAll('.js-project-item');
                    if (gridItems.length) {
                        for (let i = 0; i < gridItems.length; i++) {
                            gridItems[i].style.visibility = 'hidden';
                            gridContainer.appendChild(gridItems[i]);
                            imagesLoaded(gridItems[i], { background: '.projects-item__bg' }, () => {
                                // console.log('Item ', i, ' loaded');
                                gridItems[i].style.visibility = 'visible';
                                const time = Math.random() * 500;
                                setTimeout(() => {
                                    gridItems[i].classList.add('item-reveal');
                                }, time);
                            });
                        }
                    }
                    const responseNextPage = responseHTML.querySelector('.next.page-numbers');
                    if (responseNextPage) {
                        url = responseNextPage.getAttribute('href');
                    } else {
                        loadMoreButton.removeEventListener('click', loadMoreButtonHandler);
                        loadMoreButton.style.display = 'none';
                    }
                    loadMoreButton.classList.remove('loading');
                    loadMoreButton.disabled = false;
                    document.body.classList.remove('loading-items');
                    responseHTML = null;
                    document.dispatchEvent(projectsAddedEvent);
                },
            );
        };
        loadMoreButton.addEventListener('click', loadMoreButtonHandler);
    }
});
