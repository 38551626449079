/* global Sine */
/* eslint no-unused-vars: off */

import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import TweenMax from 'gsap/src/uncompressed/TweenMax';
import TimelineMax from 'gsap/src/uncompressed/TimelineMax';
// import TweenLite from 'gsap/src/uncompressed/TweenLite';

// For debug
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';

/*
function animateCounter(el, to, time) {
    const counterElement = el;
    const currentCount = parseInt(el.innerHTML, 10);
    const futureCount = parseInt(to, 10);
    if (isNaN(currentCount)) {
        console.log('error currentCount');
        return;
    }
    if (isNaN(futureCount)) {
        console.log('error futureCount');
        return;
    }
    const counter = { text: currentCount };
    TweenMax.to(counter,
        time,
        {
            text: futureCount,
            onUpdate: () => {
                const updateText = Math.floor(counter.text);
                counterElement.innerHTML = updateText;
            },
            ease: Sine.easeOut,
        });
}
*/
function updateDate(el, date) {
    const counterElement = el;
    counterElement.innerHTML = date;
}
// Page Intro animations
function homePageIntroAnimations(controller) {
    const pageIntro = document.querySelector('.page-intro-section');
    if (pageIntro) {
        const sectionMedia = pageIntro.querySelector('.page-intro__media-wrapper');
        const sectionContent = pageIntro.querySelector('.page-intro__content-wrapper');
        const scrollIcon = pageIntro.querySelector('.scroll-icon-wrapper');
        const pageBgElement = document.querySelector('.page-bg-wrapper .bg-image');
        const sectionElements = [sectionContent, scrollIcon];

        const nextSection = pageIntro.nextElementSibling;
        // console.log('triggerElement: ', nextSection);
        const nextSectionElements = [
            nextSection.querySelector('.section-suptitle'),
            nextSection.querySelector('.section-title'),
            nextSection.querySelector('.section-content'),
            nextSection.querySelector('.images-column'),
            nextSection.querySelector('.crossite-blocks-container'),
        ].filter(el => el !== null);

        const scene = new ScrollMagic.Scene({
            triggerElement: nextSection,
            triggerHook: 0.7,
        });
        scene.addTo(controller);

        const showIntro = () => {
            const tl = new TimelineMax()
                // Hide Background
                .add(TweenMax.to(
                    [pageBgElement, sectionMedia],
                    1.5,
                    {
                        autoAlpha: 0,
                        y: 50,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'preexisting',
                    },
                ))
                // Hide elements of the section we just left
                .add(TweenMax.to(
                    nextSectionElements,
                    1,
                    {
                        autoAlpha: 0,
                        y: 50,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'all',
                    },
                ), '-=1.2')
                // Clear Background image
                .set(
                    pageBgElement,
                    {
                        backgroundImage: 'url()',
                        overwrite: 'preexisting',
                    },
                )
                // Show Background
                .add(TweenMax.fromTo(
                    sectionMedia,
                    1.5,
                    {
                        autoAlpha: 0,
                        y: -50,
                        force3D: true,
                    },
                    {
                        autoAlpha: 1,
                        y: 0,
                        force3D: true,
                        ease: Sine.easeOut,
                    },
                ));
            // Show New Section Elements
            tl.add(TweenMax.fromTo(
                sectionElements,
                1,
                {
                    autoAlpha: 0,
                    y: -50,
                    force3D: true,
                },
                {
                    autoAlpha: 1,
                    y: 0,
                    force3D: true,
                    ease: Sine.easeOut,
                },
            ), '-=1');
        };
        const hideIntro = () => {
            // console.log('left: page intro');
            const tl = new TimelineMax(); // tween when we scroll lower

            // Hide section Media
            tl.add(TweenMax.fromTo(sectionMedia,
                1.5,
                {
                    autoAlpha: 1,
                    y: 0,
                    force3D: true,
                },
                {
                    autoAlpha: 0,
                    y: -50,
                    force3D: true,
                    ease: Sine.easeIn,
                    overwrite: 'preexisting',
                },
            ));
            // Hide section Elements
            tl.add(TweenMax.fromTo(
                sectionElements,
                1,
                {
                    autoAlpha: 1,
                    y: 0,
                    force3D: true,
                },
                {
                    autoAlpha: 0,
                    y: -20,
                    force3D: true,
                    ease: Sine.easeIn,
                    overwrite: 'preexisting',
                },
            ), '-=1.5');
        };

        // This is when we scroll down and move to the next section
        scene.on('enter', hideIntro);
        // This is when we scroll up and enter intro from the next section
        scene.on('leave', showIntro);
        showIntro();
    }
}
// Home page animations
function homePageAnimations(controller) {
    const homepageSections = document.querySelectorAll('.homepage-section');
    const pageBgElement = document.querySelector('.page-bg-wrapper .bg-image');

    const backgrounds = [];

    for (let i = 0; i < homepageSections.length; i++) {
        const section = homepageSections[i];
        const sectionBackgroundURL = section.dataset.bg;
        // console.log(i, sectionBackgroundURL);

        // populate arrays;
        backgrounds.push(sectionBackgroundURL);

        const sectionSupTitle = section.querySelector('.section-suptitle');
        const sectionTitle = section.querySelector('.section-title');
        const sectionContent = section.querySelector('.section-content');
        const sectionImages = section.querySelector('.images-column');

        const crossSiteBlocks = section.querySelector('.crossite-blocks-container');
        const productsSectionContent = section.querySelector('.products-section-content-wrapper');
        const newsSlider = section.querySelector('.news-slider');

        let sectionElements = [sectionSupTitle, sectionTitle, sectionContent, sectionImages,
            crossSiteBlocks, productsSectionContent, newsSlider,
        ];
        // Discard empty/non existent elements from array
        sectionElements = sectionElements.filter(el => el !== null);

        let prevSectionElements = [];
        let prevSection = false;
        if (i > 0) {
            const j = i - 1;
            prevSection = homepageSections[j];
            const prevSectionSupTitle = prevSection.querySelector('.section-suptitle');
            const prevSectionTitle = prevSection.querySelector('.section-title');
            const prevSectionContent = prevSection.querySelector('.section-content');
            const prevSectionImages = prevSection.querySelector('.images-column');

            const prevCrossSiteBlocks = prevSection.querySelector('.crossite-blocks-container');
            const prevProductsSectionContent = prevSection.querySelector('.products-section-content-wrapper');
            const prevNewsSlider = prevSection.querySelector('.news-slider');

            prevSectionElements = [
                prevSectionSupTitle,
                prevSectionTitle,
                prevSectionContent,
                prevSectionImages,
                prevCrossSiteBlocks,
                prevProductsSectionContent,
                prevNewsSlider,
            ].filter(el => el !== null);
        } else {
            // prevSection is page intro
            prevSection = section.previousElementSibling;
            prevSectionElements = [
                prevSection.querySelector('.page-intro__media-wrapper'),
                prevSection.querySelector('.page-intro__content-wrapper'),
                prevSection.querySelector('.scroll-icon-wrapper'),
            ].filter(el => el !== null);
        }
        const enterScene = () => {
            // Entering current section while scrolling down
            // console.log('enter', i);
            const tl = new TimelineMax();
            // Hide, change, show Background
            tl.add(TweenMax.to(pageBgElement,
                1.5,
                {
                    autoAlpha: 0,
                    y: -50,
                    force3D: true,
                    ease: Sine.easeIn,
                    overwrite: 'preexisting',
                },
            )).set(pageBgElement, {
                backgroundImage: `url(${backgrounds[i]})`,
                overwrite: 'preexisting',
            }).add(TweenMax.to(pageBgElement,
                1.5,
                {
                    autoAlpha: 0.55,
                    y: 0,
                    force3D: true,
                    ease: Sine.easeOut,
                },
            ));
            if (prevSection) {
                // Hide elements of the section we just left
                tl.add(TweenMax.to(
                    prevSectionElements,
                    1,
                    {
                        autoAlpha: 0,
                        y: -50,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'all',
                    },
                ), '-=3');
            }
            // Show section Elements
            tl.add(TweenMax.staggerFromTo(
                sectionElements,
                0.75,
                {
                    autoAlpha: 0,
                    y: 20,
                    force3D: true,
                },
                {
                    autoAlpha: 1,
                    y: 0,
                    force3D: true,
                    ease: Sine.easeOut,
                },
                0.3,
            ));
        };
        const enterPreviousScene = () => {
            // Entering previous section while scrolling up
            // console.log('leave', i, ' and enter ', i - 1);
            if (i < 1) {
                return;
            }
            const tl = new TimelineMax()
                // Hide Background
                .add(TweenMax.to(
                    pageBgElement,
                    1.5,
                    {
                        autoAlpha: 0,
                        y: 50,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'preexisting',
                    },
                ))
                // Hide elements of the section we just left
                .add(TweenMax.to(
                    sectionElements,
                    1,
                    {
                        autoAlpha: 0,
                        y: 50,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'all',
                    },
                ), '-=1.2')
                // Change Background image
                .set(
                    pageBgElement,
                    {
                        backgroundImage: `url(${backgrounds[i - 1]})`,
                        overwrite: 'preexisting',
                    },
                )
                // Show Background
                .add(TweenMax.to(
                    pageBgElement,
                    1.5,
                    {
                        autoAlpha: 0.55,
                        y: 0,
                        force3D: true,
                        ease: Sine.easeOut,
                    },
                ));
            // Show New Section Elements
            tl.add(TweenMax.staggerFromTo(
                prevSectionElements,
                0.75,
                {
                    autoAlpha: 0,
                    y: 20,
                    force3D: true,
                },
                {
                    autoAlpha: 1,
                    y: 0,
                    force3D: true,
                    ease: Sine.easeOut,
                },
                0.3,
            ));
        };

        // Set Scene
        const scene = new ScrollMagic.Scene({
            triggerElement: section,
            triggerHook: 0.7,
        });
        scene.on('enter', enterScene)
            .on('leave', enterPreviousScene)
            .addTo(controller);
    }
}
// History page animations
function historyPageAnimations(controller) {
    const historySections = document.querySelectorAll('.history-section');
    const eventDateElement = document.querySelector('.page-event-year .year');
    const pageBgElement = document.querySelector('.page-bg-wrapper .bg-image');

    // Initialize page elements: date and bg
    // eventDateElement.innerHTML = document.querySelector('.history-section').dataset.year;

    const dates = [];
    const backgrounds = [];

    for (let i = 0; i < historySections.length; i++) {
        const section = historySections[i];
        const eventYear = section.dataset.year;
        const sectionBackgroundURL = section.dataset.bg;
        // console.log(i, eventYear, sectionBackgroundURL);

        // populate arrays;
        dates.push(eventYear);
        backgrounds.push(sectionBackgroundURL);

        const sectionSupTitle = section.querySelector('.section-suptitle');
        const sectionTitle = section.querySelector('.section-title');
        const sectionContent = section.querySelector('.section-content');
        const sectionImages = section.querySelector('.images-column');

        let sectionElements = [sectionSupTitle, sectionTitle, sectionContent, sectionImages];
        // Discard empty/non existent elements from array
        sectionElements = sectionElements.filter(el => el !== null);

        let prevSectionElements = [];
        if (i > 0) {
            const j = i - 1;
            const prevSection = historySections[j];
            const prevSectionSupTitle = prevSection.querySelector('.section-suptitle');
            const prevSectionTitle = prevSection.querySelector('.section-title');
            const prevSectionContent = prevSection.querySelector('.section-content');
            const prevSectionImages = prevSection.querySelector('.images-column');

            prevSectionElements = [prevSectionSupTitle,
                prevSectionTitle,
                prevSectionContent,
                prevSectionImages,
            ].filter(el => el !== null);
        }
        const enterScene = () => {
            // Entering current section while scrolling down
            // console.log('enter', i);
            const tl = new TimelineMax()
                // Hide Background
                .add(TweenMax.to(pageBgElement,
                    1.5,
                    {
                        autoAlpha: 0,
                        y: -50,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'preexisting',
                    },
                ))
                // Hide Date
                .add(TweenMax.to(
                    eventDateElement,
                    0.5,
                    {
                        autoAlpha: 0,
                        y: 10,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'preexisting',
                    },
                ), '-=1')
                // .add(() => {
                // animateCounter(eventDateElement, dates[i], 0.45);
                // })
                // Update Date
                .call(updateDate, [eventDateElement, dates[i]])
                // Update Background
                .set(pageBgElement, {
                    backgroundImage: `url(${backgrounds[i]})`,
                    overwrite: 'preexisting',
                })
                // Show Background
                .add(TweenMax.to(pageBgElement,
                    1.5,
                    {
                        autoAlpha: 0.55,
                        y: 0,
                        force3D: true,
                        ease: Sine.easeOut,
                    },
                ))
                // Show Date
                .add(TweenMax.to(
                    eventDateElement,
                    0.5,
                    {
                        autoAlpha: 1,
                        y: 0,
                        force3D: true,
                        ease: Sine.easeOut,
                        overwrite: 'preexisting',
                    },
                ), '-=1');
            if (i > 0) {
                // Hide elements of the section we just left
                tl.add(TweenMax.to(
                    prevSectionElements,
                    1,
                    {
                        autoAlpha: 0,
                        y: -50,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'all',
                    },
                ), '-=3');
            }
            // Show section Elements
            tl.add(TweenMax.staggerFromTo(
                sectionElements,
                1,
                {
                    autoAlpha: 0,
                    y: 20,
                    force3D: true,
                },
                {
                    autoAlpha: 1,
                    y: 0,
                    force3D: true,
                    ease: Sine.easeOut,
                },
                0.25,
            ));
        };
        const enterPreviousScene = () => {
            // Entering previous section while scrolling up
            // console.log('leave', i, ' and enter ', i - 1);
            if (i < 1) {
                return;
            }
            const tl = new TimelineMax()
                // Hide Background
                .add(TweenMax.to(
                    pageBgElement,
                    1.5,
                    {
                        autoAlpha: 0,
                        y: 50,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'preexisting',
                    },
                ))
                // Hide Date
                .add(TweenMax.to(
                    eventDateElement,
                    0.5,
                    {
                        autoAlpha: 0,
                        y: 10,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'preexisting',
                    },
                ), '-=1')
                // Hide elements of the section we just left
                .add(TweenMax.to(
                    sectionElements,
                    1,
                    {
                        autoAlpha: 0,
                        y: 50,
                        force3D: true,
                        ease: Sine.easeIn,
                        overwrite: 'all',
                    },
                ), '-=1.2')
                // Animate counter
                // .add(() => {
                //    animateCounter(eventDateElement, dates[i - 1], 0.45);
                // })
                // Update Date
                .call(updateDate, [eventDateElement, dates[i - 1]])
                // Change Background image
                .set(
                    pageBgElement,
                    {
                        backgroundImage: `url(${backgrounds[i - 1]})`,
                        overwrite: 'preexisting',
                    },
                )
                // Show Background
                .add(TweenMax.to(
                    pageBgElement,
                    1.5,
                    {
                        autoAlpha: 0.55,
                        y: 0,
                        force3D: true,
                        ease: Sine.easeOut,
                    },
                ))
                // Show Date
                .add(TweenMax.to(
                    eventDateElement,
                    0.5,
                    {
                        autoAlpha: 1,
                        y: 0,
                        force3D: true,
                        ease: Sine.easeOut,
                        overwrite: 'preexisting',
                    },
                ), '-=1');
            // Show New Section Elements
            tl.add(TweenMax.staggerFromTo(
                prevSectionElements,
                1,
                {
                    autoAlpha: 0,
                    y: 20,
                    force3D: true,
                },
                {
                    autoAlpha: 1,
                    y: 0,
                    force3D: true,
                    ease: Sine.easeOut,
                },
                0.25,
            ));
        };
        // Set Scene
        const scene = new ScrollMagic.Scene({
            triggerElement: section,
            triggerHook: 0.7,
        });
        scene.on('enter', enterScene)
            .on('leave', enterPreviousScene)
            .addTo(controller);
    }
}
// Footer Animations
function footerAnimations(controller) {
    const footerWrapper = document.querySelector('.footer-wrapper');
    const scrollTopButton = footerWrapper.querySelector('.scroll-top-button');
    const footer = document.querySelector('.site-footer');

    const tl = new TimelineMax();
    tl.add(TweenMax.fromTo(
        [footer, scrollTopButton],
        0.7,
        {
            opacity: 0,
            y: 50,
            force3D: true,
        },
        {
            opacity: 1,
            y: 0,
            force3D: true,
            ease: Sine.easeOut,
        },
    ));

    const scene = new ScrollMagic.Scene({
        triggerElement: footerWrapper,
        triggerHook: 0.7,
    });
    scene.setTween(tl);
    scene.addTo(controller);
}
// Header Animations
function headerAnimations(controller) {
    const header = document.querySelector('.site-header');
    const logo = header.querySelector('.site-header__logo-wrapper');
    const ls = header.querySelector('.site-header__ls-wrapper');
    const menuBtn = header.querySelector('.site-header__toggle-menu-wrapper');

    const tl = new TimelineMax();
    tl.add(TweenMax.fromTo(
        [logo, ls, menuBtn],
        0.7,
        {
            opacity: 0,
            y: -30,
            force3D: true,
        },
        {
            opacity: 1,
            y: 0,
            force3D: true,
            ease: Sine.easeOut,
        },
    ));

    const hideHeader = () => {
        TweenMax.to(
            [logo, ls, menuBtn],
            0.5,
            {
                opacity: 0,
                y: -30,
                force3D: true,
                ease: Sine.easeOut,
            },
        );
    };
    const showHeader = () => {
        TweenMax.to(
            [logo, ls, menuBtn],
            0.5,
            {
                opacity: 1,
                y: 0,
                force3D: true,
                ease: Sine.easeOut,
                // delay: 0.35,
            },
        );
    };

    const scene = new ScrollMagic.Scene({
        triggerElement: header,
        triggerHook: 0,
        offset: 100,
    });
    scene.on('enter', hideHeader);
    scene.on('leave', showHeader);
    scene.addTo(controller);
}
document.addEventListener('DOMContentLoaded', () => {
    // check if animations are on
    if (document.querySelector('.js-scrlmgc')) {
        // Set scrollmagic controller
        // for debug: addIndicators: true
        // const controller = new ScrollMagic.Controller({ addIndicators: true });
        const controller = new ScrollMagic.Controller();

        if (document.querySelector('.history-page') !== null) {
            // we are in history page
            historyPageAnimations(controller);
        } else if (document.querySelector('.fino-home') !== null) {
            // we are in home page
            homePageIntroAnimations(controller);
            homePageAnimations(controller);
        } else {
            // other pages
            // pageIntroAnimations(controller);
        }
        // headerAnimations(controller);
        footerAnimations(controller);
    }
});
